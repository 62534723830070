import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import Helmet from 'react-helmet';

const NotFoundPage = () => (
    <Layout>
        <Helmet title="Not found " />
        <Header siteTitle={'Reactgo'} />
        <div
            style={{ marginTop: '10rem', display: 'flex', justifyContent: 'center' }}
        >
            <div>
                <h1>↩ NOT FOUND ↩</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage